import { lazy } from 'react';
import { createRoot } from 'react-dom/client';

let node = document.createElement('div');
node.id = 'mozart-bed-package-data';
document.body.appendChild(node);

const links = document.querySelectorAll('a[href*="#bed-package-data-popup"]');

if (links.length > 0) {

    const BedPackageData = lazy(() => import('./index'));

    createRoot(node)
        .render(<BedPackageData />);
}
